<template>
  <v-container id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card color="success" light max-width="100%" width="400" class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Login</h1>
            </div>
          </template>
          <v-form @submit.prevent="onSubmit">
            <v-card-text class="text-center">
              <div class="text-center grey--text body-1 font-weight-light">&nbsp;</div>
              <!-- v-text-field
              color="secondary"
              label="First Name..."
              prepend-icon="mdi-face"
              class="mt-10"
              id="firstname"
              v-model="form.firstname"
              type="firstname"
              required
              placeholder="Enter firstname"
            />
            <v-text-field
              color="secondary"
              label="Last Name..."
              prepend-icon="mdi-face"
              class="mt-10"
              id="lastname"
              v-model="form.lastname"
              type="lastname"
              required
              placeholder="Enter lastname"
              /-->
              <v-text-field
                color="primry"
                label="Email..."
                class="mt-10"
                prepend-icon="mdi-email"
                id="email"
                v-model="formLogin.email"
                type="email"
                autocomplete="on"
                required
                placeholder="Enter email"
              />

              <v-text-field
                class="mb-8"
                color="secondary"
                label="Password..."
                prepend-icon="mdi-lock-outline"
                id="password"
                autocomplete="on"
                v-model="formLogin.password"
                type="password"
                required
                placeholder="Enter password"
              />

              <pages-btn
                type="submit"
                large
                color
                depressed
                class="v-btn--text success--text"
              >Let's Go</pages-btn>
            </v-card-text>
          <v-snackbar
            v-model="retSnackbar.show"
            :bottom="retSnackbar.y === 'bottom'"
            :color="retSnackbar.color"
            :left="retSnackbar.x === 'left'"
            :multi-line="retSnackbar.mode === 'multi-line'"
            :right="retSnackbar.x === 'right'"
            :timeout="retSnackbar.timeout"
            :top="retSnackbar.y === 'bottom'"
            :vertical="retSnackbar.mode === 'vertical'"
          >
            {{ retSnackbar.text }}
            <v-btn dark text @click="retSnackbar.show = false">Close</v-btn>
          </v-snackbar>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { gexLogin } from "@/views/dashboard/components/core/orarest";

const initUser = {
  marketid: 0, // process.env.VUE_APP_MARKETID,
  firstname: "",
  lastname: "",
  email: "",
  password: ""
};
export default {
  name: "PagesLogin",
  components: {
    PagesBtn: () => import("./components/Btn")
  },
  metaInfo: {
    title: "Login",
    titleTemplate: "Login Gothar Exercise",
    description: "Login Gothar Exercise."
  },
  data() {
    return {
      formLogin: Object.assign({}, initUser),
      retSnackbar: {
        show: false,
        color: "error",
        timeout: 8000,
        text: "Username/password was not correct. Please try again.",
        mode: "",
        x: null,
        y: "bottom"
      }
    };
  },
  methods: {
    async onSubmit() {
      if (this.formLogin.email != "" && this.formLogin.password != "") {
        var response = await gexLogin(this.formLogin);
        console.info("loginform response: ", response);
        if (response.status == 200) {
          this.$router.push("/excercise/gothar");
        } else {
          // this.retSnackbar.text = response.retmsg;
          this.retSnackbar.show = true;
        }
        //this.newExcercise = Object.assign({}, initExcercise);
        //this.showLoading = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    }
  }
};
</script>
